* {
  font-optical-sizing: auto;
  cursor: default;
  font-family: Inter, sans-serif;
  font-style: normal;
}

body {
  background-color: var(--black-95);
}

.footer-content, .footer-content-wrapper, .landing {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  display: flex;
}

.footer-content-wrapper, .landing {
  box-sizing: border-box;
}

.footer-content-wrapper {
  padding: 0 16px;
}

.landing {
  background-color: var(--color-black);
  padding: 0 0 var(--padding-13xl);
  letter-spacing: normal;
  flex-direction: column;
  width: 100%;
  line-height: normal;
  position: relative;
  overflow: hidden;
}

.frame-parent, .homepage {
  justify-content: flex-start;
  align-items: flex-start;
  gap: var(--gap-9xs);
  flex-direction: row;
  display: flex;
}

.homepage {
  padding: var(--padding-base);
  justify-content: space-between;
  align-self: stretch;
  gap: var(--gap-xl);
  background-color: var(--color-black);
}

.homepage-wrapper {
  justify-content: space-between;
  align-self: stretch;
  width: 100%;
  display: flex;
}

.homepage-child {
  align-self: stretch;
  width: 115.5px;
  min-height: 36px;
  max-height: 100%;
  position: relative;
}

.frame-wrapper {
  cursor: pointer;
  border-radius: var(--br-13xl);
  background-color: var(--black-85);
  padding: var(--padding-3xs);
  box-sizing: border-box;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  display: flex;
  box-shadow: inset 0 -1px #00000040;
}

.frame-icon {
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  position: relative;
  overflow: hidden;
}

.st {
  padding: 0 var(--padding-base) 444px;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-end;
  gap: var(--gap-21xl);
  text-align: center;
  font-size: var(--font-size-13xl);
  color: var(--black-0);
  font-family: var(--font-inter);
  flex-direction: column;
  display: flex;
  position: relative;
}

.st-phones {
  display: none;
}

.bg-icon {
  object-fit: cover;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  position: absolute;
  inset: 0;
  overflow: hidden;
  margin: 0 !important;
}

.app-info, .olimp-description, .olimp {
  align-self: stretch;
}

.app-info {
  justify-content: flex-start;
  align-items: flex-start;
  gap: var(--gap-base);
  flex-direction: column;
  display: flex;
}

.olimp {
  z-index: 1;
  text-align: center;
  color: var(--black-0);
  width: 343px;
  margin: 0 auto;
  font-family: inherit;
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  position: relative;
}

.olimp-description {
  font-size: var(--font-size-base);
  color: var(--black-10);
  z-index: 1;
  z-index: 1;
  width: 343px;
  margin: 0 auto;
  font-weight: 500;
  line-height: 24px;
  position: relative;
}

.download-buttons-container, .frame-group {
  flex-direction: row;
  align-items: flex-start;
  display: flex;
}

.download-buttons-container {
  justify-content: center;
  align-self: stretch;
  padding: 0 38px 0 39px;
}

.frame-button {
  cursor: pointer;
  padding: var(--padding-base) var(--padding-21xl);
  background-color: var(--color-mintcream);
  border-radius: var(--br-13xl);
  box-sizing: border-box;
  z-index: 1;
  border: 0;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 129px;
  min-width: 84px;
  display: flex;
  box-shadow: 0 0 4px #00000080, inset 0 -2px #00000040;
}

.frame-group {
  gap: var(--gap-5xs);
}

.frame-icon2 {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  min-height: 20px;
  position: relative;
  overflow: hidden;
}

.ios {
  font-size: var(--font-size-sm);
  font-weight: 500;
  line-height: 20px;
  font-family: var(--font-inter);
  color: var(--color-black);
  text-align: center;
  min-width: 24px;
  display: inline-block;
  position: relative;
}

.android {
  font-size: var(--font-size-sm);
  font-weight: 500;
  line-height: 20px;
  font-family: var(--font-inter);
  color: var(--color-black);
  text-align: center;
  min-width: 53px;
  display: inline-block;
  position: relative;
}

.feature-icons4, .frame-section-wrapper, .frame-section {
  box-sizing: border-box;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  gap: var(--gap-5xs);
  flex-direction: column;
  max-width: 100%;
  display: flex;
}

.frame-section {
  border-radius: 0 0 var(--br-5xl) var(--br-5xl);
  background-color: var(--black-95);
  text-align: center;
  font-size: var(--font-size-lg);
  color: var(--black-0);
  font-family: var(--font-inter);
  padding: 80px var(--padding-base);
}

.feature-icons4 {
  border-radius: var(--br-5xl);
  background-color: var(--color-gray-200);
  border: 8px solid var(--color-gray-200);
  z-index: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 343px;
  margin: 0;
  padding: 0;
  font-size: 20px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.frame-div, .parent {
  justify-content: flex-start;
  max-width: 100%;
  display: flex;
}

.parent {
  align-items: center;
  gap: var(--gap-5xl);
  flex-direction: column;
  flex: 1;
}

.frame-div {
  padding: 0 0 var(--padding-base);
  box-sizing: border-box;
  font-size: var(--font-size-13xl);
  flex-direction: row;
  align-self: stretch;
  align-items: flex-start;
}

.p, .p1 {
  margin: 0;
}

.div1, .h1 {
  position: relative;
}

.fan-benefits {
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: var(--gap-5xs);
  flex-direction: row;
  padding: 0 5px;
  display: flex;
}

.benefit-labels {
  border: 1px solid var(--color-gray-400);
  padding: var(--padding-5xs) var(--padding-mini);
  filter: drop-shadow(8px 16px 32px #00000026);
  backdrop-filter: blur(8px);
  border-radius: var(--br-13xl);
  box-sizing: border-box;
  background-color: #0000;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.benefit-labels-text {
  font-size: var(--font-size-sm);
  font-weight: 500;
  line-height: 18px;
  font-family: var(--font-inter);
  color: var(--color-gray-300);
  text-align: center;
  display: inline-block;
}

.div4 {
  font-size: var(--font-size-sm);
  color: var(--black-20);
  z-index: 2;
  object-fit: cover;
  align-self: stretch;
  width: 311px;
  font-weight: 500;
  line-height: 20px;
  position: relative;
}

.interface-container-parent {
  border-radius: var(--br-5xl);
  background-color: var(--color-gray-200);
  box-sizing: border-box;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: flex-start;
  align-self: stretch;
  align-items: center;
  gap: 167.7px;
  max-width: 100%;
  height: 540px;
  padding: 1px 0 0;
  display: flex;
  overflow: hidden;
}

.interface-container {
  padding: var(--padding-3xs) var(--padding-13xl) 674px;
  box-sizing: border-box;
  justify-content: flex-start;
  align-items: flex-start;
  gap: var(--gap-xl);
  flex-direction: column;
  flex-shrink: 0;
  max-width: 110%;
  display: flex;
  position: relative;
}

.ellipse-parent {
  width: 100%;
  height: 100%;
  margin: 0 !important;
}

.frame-child {
  background: radial-gradient(50% 50%, #0e915580, #0e915500);
  border-radius: 50%;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.image-244-icon {
  object-fit: contain;
  z-index: 2;
  text-align: center;
  width: 311px;
  height: 631px;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  top: 150px;
  left: 50%;
  right: 0;
  transform: translateX(-50%);
}

.frame-parent2, .nested-interface {
  flex-direction: row;
  align-items: flex-start;
  display: flex;
}

.nested-interface {
  padding: 0 var(--padding-xl);
  justify-content: center;
  align-self: stretch;
}

.frame-parent2 {
  backdrop-filter: blur(8px);
  border-radius: var(--br-13xl);
  background-color: var(--color-gray-400);
  border: 1px solid var(--color-gray-500);
  box-sizing: border-box;
  z-index: 2;
  justify-content: flex-start;
  padding: 0 2px;
}

.frame-icon4 {
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  position: relative;
  overflow: hidden;
}

.frame-wrapper1 {
  cursor: pointer;
  padding: var(--padding-3xs);
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.frame-wrapper1.active {
  border-radius: var(--br-13xl);
  background-color: var(--color-gray-400);
}

.frame-icon5 {
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  position: relative;
  overflow: hidden;
}

.interface-description {
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  gap: var(--gap-5xs);
  flex-direction: column;
  display: flex;
}

.convenience-heading {
  text-align: center;
  width: 100%;
}

.b {
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
}

.interface-container {
  flex-shrink: 0;
  position: relative;
}

.feature-icons {
  border-radius: var(--br-5xl);
  background-color: var(--color-gray-200);
  padding: var(--padding-xl) 138px var(--padding-xl) 139px;
  box-sizing: border-box;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-height: 343px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.b1 {
  z-index: 1;
  text-align: center;
  min-width: 66px;
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  display: inline-block;
  position: relative;
}

.fast-payouts, .wide-selection {
  position: absolute;
  margin: 0 !important;
}

.wide-selection {
  width: 100%;
  left: calc(50% - 187.5px);
}

.wide-selection-background {
  z-index: 5;
  background: radial-gradient(36.8% 53.58% at 63.72% 43.95%, #0f965633 0%, #065d5800 100%);
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  top: 90px;
  left: -18%;
}

.bonus-description-icon {
  z-index: 1;
  width: 261.4px;
  height: 104.5px;
  position: absolute;
  top: 46px;
  left: 57px;
}

.two-black-dices-with-different-icon {
  object-fit: contain;
  z-index: 2;
  width: 308.37px;
  height: 308.37px;
  position: absolute;
  top: 60px;
  left: 35px;
}

.feature-icons1 {
  border-radius: var(--br-5xl);
  background-color: var(--color-gray-200);
  flex-shrink: 0;
  align-self: stretch;
  height: 343px;
  position: relative;
  overflow: hidden;
}

.feature-icons1-images {
  flex-direction: column;
  align-items: center;
  gap: 12px;
  display: flex;
}

.feature-icons1-images-0, .feature-icons1-images-1 {
  gap: 16px;
  display: flex;
}

.fast-payouts {
  transform-origin: 0 0;
  z-index: 1;
  background: radial-gradient(50% 50%, #0f965633, #065d5800);
  border-radius: 50%;
  width: 634.8px;
  height: 632.6px;
  top: 226.53px;
  left: 599.2px;
  transform: rotate(145.4deg);
}

.rectangle-icon {
  object-fit: contain;
  width: 748px;
  height: -317px;
  position: absolute;
  top: 821px;
  left: 0;
}

.p2, .p3 {
  text-align: center;
  margin: 0;
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
}

.b2 {
  margin: 20px auto 27px;
  line-height: 26px;
}

.image-250 {
  border-radius: var(--br-45xl);
  border: 1px solid var(--color-gray-500);
  box-sizing: border-box;
  padding: var(--padding-xl) var(--padding-lgi);
  border-radius: 64px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 104px;
  height: 104px;
  display: flex;
  position: relative;
  box-shadow: 4px 8px 16px #00000026;
}

.image-250-icon {
  width: 64px;
  height: 64px;
  position: relative;
}

.color-1 {
  background: #593987;
  border: 1px solid #fff3;
  box-shadow: 4px 8px 16px #00000026;
}

.color-2 {
  background: #231f20;
  border: 1px solid #fff3;
  box-shadow: 4px 8px 16px #00000026;
}

.color-3 {
  background: #d20515;
  border: 1px solid #fff3;
  box-shadow: 4px 8px 16px #00000026;
}

.color-4 {
  background: #07073f;
  border: 1px solid #fff3;
  box-shadow: 4px 8px 16px #00000026;
}

.feature-icons2 {
  border-radius: var(--br-5xl);
  background-color: var(--color-gray-200);
  font-size: var(--font-size-13xl);
  flex-direction: column;
  flex-shrink: 0;
  align-self: stretch;
  align-items: center;
  height: 343px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.h11 {
  -webkit-text-fill-color: transparent;
  z-index: 2;
  background: linear-gradient(162.76deg, #1eb769, #0f7273);
  -webkit-background-clip: text;
  margin: 32px auto;
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
}

.wrapper-gift-front {
  z-index: 3;
  justify-content: center;
  align-items: center;
  width: 406px;
  height: 406px;
  display: flex;
  top: 64px;
  left: -14px;
}

.gift-front-icon {
  z-index: 3;
  justify-content: center;
  align-items: center;
  width: 406px;
  height: 406px;
  display: flex;
  position: relative;
  top: -80px;
}

.feature-icons-child {
  border: 8px solid var(--color-gray-200);
  z-index: 0;
  width: 343px;
  display: none;
}

.b3, .bets-icon-parent {
  margin: 0 auto;
}

.b3 {
  line-height: 26px;
  display: inline-block;
}

.frame-parent3 {
  font-size: var(--font-size-sm);
  color: var(--black-20);
  flex-shrink: 0;
  max-width: 110%;
  margin-left: -16px;
  position: relative;
}

.group-parent {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: flex;
}

.group-parent-wrapper {
  box-sizing: border-box;
  backdrop-filter: blur(4px);
  cursor: auto;
  background: #262626;
  border: 1px solid;
  border-radius: 20px;
  flex-direction: row;
  align-items: center;
  gap: 24px;
  max-width: 311px;
  height: 56px;
  padding: 12px;
  display: flex;
}

.group-parent-wrapper:first-child {
  border-image-source: linear-gradient(97.31deg, #ffffff13 0%, #fff0 25.5% 73.5%, #ffffff06 100%);
  width: 311px;
}

.group-parent-wrapper:nth-child(2) {
  opacity: .7;
  border-image-source: linear-gradient(97.31deg, #ffffff13 0%, #fff0 25.5% 73.5%, #ffffff06 100%);
  width: 301.39px;
}

.group-parent-wrapper:nth-child(3) {
  opacity: .4;
  border-image-source: linear-gradient(97.31deg, #ffffff13 0%, #fff0 25.5% 73.5%, #ffffff06 100%);
  width: 291.79px;
}

.group-parent-wrapper:nth-child(4) {
  opacity: 0;
  border-image-source: linear-gradient(97.31deg, #ffffff13 0%, #fff0 25.5% 73.5%, #ffffff06 100%);
}

.group-parent-img-icon {
  width: 17px;
  height: 17px;
}

.group-parent-body {
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  width: 100%;
  display: flex;
}

.group-parent-money {
  text-align: left;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
}

.group-parent-text {
  text-align: left;
  color: #ffffff80;
  font-size: 10px;
  font-weight: 500;
}

.group-parent-time {
  text-align: left;
  color: #ffffff80;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
}

.group-icon {
  width: 311px;
  height: 179px;
  display: flex;
}

.bonus-icon {
  transform-origin: center;
  z-index: 2;
  background: radial-gradient(50% 50%, #0e915533, #0e915500);
  border-radius: 50%;
  width: 100%;
  height: 748px;
  position: absolute;
}

.div5 {
  font-size: var(--font-size-sm);
  color: var(--black-20);
  margin: 8px auto 40px;
}

.frame-parent3 {
  flex-shrink: 0;
  width: 375px;
  max-width: 110%;
  height: 579px;
  margin-left: -16px;
  position: relative;
}

.feature-icons-child, .feature-icons3 {
  border-radius: var(--br-5xl);
  box-sizing: border-box;
  max-width: 100%;
  height: 343px;
  position: relative;
}

.feature-icons3 {
  background-color: var(--color-gray-200);
  flex-direction: column;
  flex-shrink: 0;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  padding: 23px 0 0;
  display: flex;
  overflow: hidden;
}

.feature-icons-child {
  border: 8px solid var(--color-gray-200);
  z-index: 0;
  width: 343px;
}

.images-lightning {
  z-index: 3;
  width: 48px;
  height: 48px;
  top: 249.5px;
  left: 163.5px;
}

.frame-parent4 {
  margin: 0 auto;
}

.frame-inner {
  background-image: url("group-3969.83352396.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  position: absolute;
}

.h3 {
  font-size: inherit;
  z-index: 3;
  margin: 16px auto 8px;
  font-family: inherit;
  font-weight: 700;
  line-height: 28px;
  position: relative;
}

.wrapper {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 auto;
  display: flex;
}

.div6 {
  font-size: var(--font-size-sm);
  color: var(--black-20);
  z-index: 3;
  width: 295px;
  font-weight: 500;
  line-height: 20px;
  display: inline-block;
  position: relative;
}

.image-251-icon, .mask-group-icon {
  width: 100%;
  height: 100%;
  position: absolute;
}

.mask-group-icon {
  object-fit: cover;
  background: url("mask-group.a2e80c1e.svg") center / cover no-repeat;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  position: absolute;
  inset: 0;
  overflow: hidden;
  margin: 0 !important;
}

.image-251-icon {
  object-fit: contain;
  border-radius: 18px;
}

.wrapper-image-251 {
  border-radius: 18px;
  justify-content: center;
  align-items: center;
  width: 72px;
  height: 72px;
  display: flex;
  position: relative;
}

.div7, .h13 {
  align-self: stretch;
  position: relative;
}

.h13 {
  font-size: inherit;
  margin: 0;
  font-family: inherit;
  font-weight: 700;
  line-height: 36px;
}

.div7 {
  font-size: var(--font-size-base);
  color: var(--black-10);
  width: 343px;
  margin: 0 auto;
  font-weight: 500;
  line-height: 24px;
}

.app-preview, .download-call-to-action {
  justify-content: flex-start;
  align-self: stretch;
  align-items: center;
  gap: var(--gap-base);
  flex-direction: column;
  display: flex;
}

.app-preview {
  gap: var(--gap-5xl);
}

.button-placeholders-icon {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  min-height: 20px;
  position: relative;
  overflow: hidden;
}

.ios1 {
  font-size: var(--font-size-sm);
  font-weight: 500;
  line-height: 20px;
  font-family: var(--font-inter);
  color: var(--color-black);
  text-align: center;
  min-width: 24px;
  display: inline-block;
  position: relative;
}

.button-placeholders-parent {
  cursor: pointer;
  padding: var(--padding-base) var(--padding-21xl);
  background-color: var(--color-mintcream);
  border-radius: var(--br-13xl);
  justify-content: center;
  align-items: center;
  gap: var(--gap-9xs);
  border: 0;
  flex-direction: row;
  display: flex;
  box-shadow: 0 0 4px #00000080, inset 0 -2px #00000040;
}

.frame-icon6 {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  min-height: 20px;
  position: relative;
  overflow: hidden;
}

.android1 {
  font-size: var(--font-size-sm);
  font-weight: 500;
  line-height: 20px;
  font-family: var(--font-inter);
  color: var(--color-black);
  text-align: center;
  min-width: 53px;
  display: inline-block;
  position: relative;
}

.buttons, .frame-parent5 {
  flex-direction: row;
  display: flex;
}

.frame-parent5 {
  cursor: pointer;
  padding: var(--padding-base) var(--padding-7xl);
  background-color: var(--color-mintcream);
  border-radius: var(--br-13xl);
  justify-content: center;
  align-items: center;
  gap: var(--gap-9xs);
  border: 0;
  box-shadow: 0 0 4px #00000080, inset 0 -2px #00000040;
}

.buttons {
  padding: 0 var(--padding-xl);
  justify-content: flex-start;
  align-items: flex-start;
  gap: var(--gap-5xs);
}

.download-info, .rd, .rd-wrapper {
  justify-content: flex-start;
  max-width: 100%;
  display: flex;
}

.download-info {
  align-items: center;
  gap: var(--gap-21xl);
  z-index: 1;
  flex-direction: column;
  flex: 1;
}

.rd, .rd-wrapper {
  box-sizing: border-box;
  flex-direction: row;
  align-items: flex-start;
}

.rd {
  padding: 212px var(--padding-base);
  flex: 1;
  min-height: 800px;
  position: relative;
}

.rd-wrapper {
  padding: 0 0 var(--padding-13xl);
  text-align: center;
  color: var(--black-0);
  font-size: 28px;
  font-family: var(--font-inter);
  align-self: stretch;
}

.div8, .refund-policy {
  position: relative;
}

.refund-policy {
  min-width: 91px;
  display: inline-block;
}

.aml, .div10, .div9 {
  display: inline-block;
}

.div9 {
  min-width: 67px;
  position: relative;
}

.div10 {
  min-width: 59px;
}

.div10, .div11 {
  position: relative;
}

.footer-links {
  border-bottom: 1px solid var(--black-90);
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  gap: 12px;
  padding: 0 0 16px;
  display: flex;
}

.olimp1 {
  text-align: center;
  font-size: var(--font-size-sm);
  color: var(--black-60);
  font-family: var(--font-inter);
  white-space: nowrap;
  min-width: 95px;
  padding: 16px 0 0;
  text-decoration: none;
  display: inline-block;
  position: relative;
}

a {
  cursor: pointer;
  text-align: center;
  font-size: var(--font-size-sm);
  color: var(--black-60);
  font-family: var(--font-inter);
  white-space: nowrap;
  text-decoration: none;
}

.dotted {
  display: none;
}

.buttons-mobile-sticky {
  z-index: 5;
  opacity: 0;
  width: 100%;
  position: fixed;
  bottom: 25px;
}
/*# sourceMappingURL=en.35f102d1.css.map */
